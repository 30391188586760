<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-card
            tile
            flat
            style="
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: sticky;
              top: 60px;
              background: white;
              z-index: 5;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline">
                <p
                  :style="`font-size:${
                    wWidth > 780 ? '20px' : '3vw'
                  }; margin: 0;`"
                >
                  DETAIL SURAT PENGAJUAN LEMBUR<v-tooltip bottom color="grey">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        text
                        icon
                        color="grey darken-2"
                        @click="getDetail"
                      >
                        <v-icon small>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>refresh page</span>
                  </v-tooltip>
                </p>

                <!-- <div
                  v-if="permission.update_perm == 1"
                  class="d-flex"
                  style="position: absolute; right: 0; top: 55px"
                >
                  <v-btn
                    v-if="!isEdit"
                    @click.prevent="editHeader()"
                    type="button"
                    text
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                    Edit Header
                  </v-btn>

                  <v-btn
                    v-if="isEdit"
                    @click="submitHeader"
                    type="button"
                    text
                    outlined
                    elevation="0"
                    color="success"
                    class="white--text font-weight-bold"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    <v-icon small>mdi-save</v-icon>
                    Simpan
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    @click="cancelHeader"
                    text
                    outlined
                    type="button"
                    elevation="0"
                    color="error"
                    class="white--text font-weight-bold"
                    style="font-size: 12px; margin-left: 10px"
                    :loading="loading"
                  >
                    Batal
                  </v-btn>
                </div> -->
              </v-toolbar-title>
            </div>
            <div v-if="detail !== null" class="actionButton">
              <div v-if="permission.update_perm" class="d-flex">
                <div v-if="detail.created_by == getUserProfile.employee.id">
                  <!-- <div v-if="form.employee.id === getUserProfile.employee.id"> -->
                  <v-btn
                    v-if="detail.status.id === 0"
                    @click="submitDocument"
                    type="submit"
                    rounded
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-2"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
                <!-- <div v-else-if="form.created_by_employee != null && form.created_by_employee.email === getUserProfile.employee.email">
                      <v-btn
                        v-if="form.status_request.id === 0"
                        type="submit"
                        rounded
                        elevation="0"
                        color="primary"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Submit
                      </v-btn>
                    </div> -->
                <div v-if="isAllowApprove">
                  <v-dialog
                    v-if="detail.status.id === 1"
                    v-model="approveDialog"
                    width="500"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        type="button"
                        rounded
                        elevation="0"
                        color="green"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Approve
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Form Persetujuan
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-col
                          md="12"
                          style="padding: 0; position: relative; top: 20px"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Catatan
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-textarea
                                outlined
                                v-model="detail.confirmed_note"
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="approveDialog = false"
                          :loading="loading"
                        >
                          Tutup
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click.prevent="confirmOvertime()"
                          color="primary"
                          type="button"
                          text
                          outlined
                          :loading="loading"
                        >
                          Approve
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog
                    v-if="detail.status.id === 1"
                    v-model="rejectDialog"
                    width="500"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        type="button"
                        rounded
                        elevation="0"
                        color="red"
                        class="white--text font-weight-bold ma-2"
                        style="font-size: 12px"
                        :loading="loading"
                      >
                        Reject
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Form Penolakan
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-col
                          md="12"
                          style="padding: 0; position: relative; top: 20px"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Alasan penolakan
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-textarea
                                outlined
                                v-model="detail.cancel_note"
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="rejectDialog = false"
                          :loading="loading"
                        >
                          Tutup
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click.prevent="rejectOvertime()"
                          color="primary"
                          type="button"
                          text
                          outlined
                          :loading="loading"
                        >
                          Tolak pengajuan
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <div v-if="detail.created_by == getUserProfile.employee.id">
                  <v-btn
                    v-if="detail.status.id === 0"
                    @click="openCancelDialog"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-dialog
                    v-if="detail.status.id == 0 || detail.status.id == 2"
                    v-model="cancelDialog"
                    width="500"
                    persistent
                  >
                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Form Pembatalan
                      </v-card-title>

                      <v-card-text class="d-flex justify-center align-center">
                        <v-col
                          md="12"
                          style="padding: 0; position: relative; top: 20px"
                        >
                          <div style="padding: 0 10px">
                            <v-col cols="12" style="padding: 0">
                              <p
                                class="text-left"
                                style="
                                  margin: 0;
                                  font-size: 12px;
                                  font-weight: bold;
                                  color: rgba(0, 0, 0, 0.5);
                                "
                              >
                                Alasan pembatalan
                                {{ detail.status.id == 2 ? '(HR)' : '' }}
                              </p>
                            </v-col>
                            <v-col cols="12" style="padding: 0">
                              <v-textarea
                                v-model="detail.cancel_note"
                                outlined
                              />
                            </v-col>
                          </div>
                        </v-col>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="error"
                          text
                          outlined
                          @click="cancelDialog = false"
                          :loading="loading"
                        >
                          Tutup
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click.prevent="
                            detail.status.id == 2 ? hrCancel() : selfCancel()
                          "
                          color="primary"
                          type="button"
                          text
                          outlined
                          :loading="loading"
                        >
                          Batalkan pengajuan
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

                <div
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) => id == appLevel.adminMaster
                    ) != undefined || detail.created_by == getUserProfile.employee.id
                  "
                >
                  <v-btn
                    v-if="detail.status.id == -2 || detail.status.id == -1"
                    @click.prevent="setToDraft"
                    type="button"
                    rounded
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-2"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    Set Draft
                  </v-btn>
                  <v-btn
                    v-if="detail.status.id === 2 || detail.status.id === 3"
                    @click="openCancelDialog"
                    type="button"
                    rounded
                    elevation="0"
                    color="red"
                    class="white--text font-weight-bold ma-2"
                    style="font-size: 12px"
                    :loading="loading"
                  >
                    Cancel
                  </v-btn>
                </div>
                <v-btn
                  type="button"
                  rounded
                  elevation="0"
                  color="black"
                  class="black--text font-weight-bold ma-2"
                  style="font-size: 12px"
                  @click.prevent="closeDocument"
                  :loading="loading"
                  outlined
                >
                  <v-icon small>mdi-chevron-left</v-icon>
                  Kembali
                </v-btn>
              </div>
            </div>

            <div
              v-if="detail !== null"
              class="d-flex flex-row justify-content-between align-start"
              style="position: absolute; right: 0; left: 0; top: 55px"
            >
              <v-toolbar-title
                style="padding: 0; text-align: center; height: 30px"
              >
                <div class="d-flex justify-start align-center">
                  <p style="font-size: 12px; margin: 0 15px">
                    Tgl. Buat :
                    {{ detail.created_at }} |
                    {{ detail.created_by_name }}
                  </p>
                </div>
              </v-toolbar-title>

              <div
                :style="`${statusColor(
                  detail.status.id
                )}; background-color:#4e4e4e1f;font-weight:bolder;padding:5px 15px; min-width:100px; text-align:center;`"
              >
                {{ detail.status.name.toUpperCase() }}
              </div>
            </div>
          </v-card>

          <v-card v-if="detail !== null" elevation="0">
            <v-form
              ref="entryForm"
              @submit.prevent="submitDetail()"
              lazy-validation
              :disabled="loading"
              style="position: relative"
            >
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        No. Document
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        :value="detail.document_no"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="7" style="padding: 0"> </v-col>
                <v-col cols="5" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        :value="detail.company.name"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="detail.department != null"
                  cols="4"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Department
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        :value="detail.department.name"
                        dense
                        outlined
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Keterangan
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-textarea
                        :value="detail.description"
                        dense
                        outlined
                        rows="3"
                        disabled
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" style="padding: 0 10px">
                  <v-btn
                    v-if="detail.status.id == 0 && detail.created_by == getUserProfile.employee.id"
                    @click="addEmployeeOvertime"
                    style="font-size: 12px"
                    elevation="2"
                    tilerounded="5px"
                    small
                    color="primary"
                    class="white--text ml-3 mb-2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>Tambah item</span>
                    </v-tooltip>
                    <span style="margin-left: 5px"> Tambah </span>
                  </v-btn>
                  <v-card>
                    <v-data-table
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="detailHeaders"
                      :search="searchName"
                      :items="detail.overtime_request_detail"
                      :options.sync="footer.options"
                      @update:page="updatePage"
                      @update:items-per-page="updateItemPerPage"
                      @click:row="openDetail"
                      :items-per-page="10"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [10, 15, 100, 1000]
                      }"
                      style="cursor: pointer"
                    >
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + footer.offset * footer.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee.nik`]="{ item }">
                        <div
                          :style="`width:100px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.employee.nik }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee.name`]="{ item }">
                        <div
                          :style="`width:120px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.employee.name }}
                        </div>
                      </template>
                      <template v-slot:[`item.company.name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.company.name }}
                        </div>
                      </template>
                      <template v-slot:[`item.shift`]="{ item }">
                        <div
                          :style="`width:75px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.shift != null ? item.shift.name : '-' }}
                        </div>
                      </template>
                      <template v-slot:[`item.is_break`]="{ item }">
                        <div
                          :style="`color:${
                            item.is_break ? 'green' : 'red'
                          }; width:75px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.is_break ? 'Yes' : 'No' }}
                        </div>
                      </template>
                      <template v-slot:[`item.total_hour`]="{ item }">
                        <div
                          :style="`width:100px; font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.total_hour }}
                        </div>
                      </template>
                      <template v-slot:[`item.request_start_time`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.request_start_time) }}
                        </div>
                      </template>
                      <template v-slot:[`item.request_end_time`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.request_end_time) }}
                          <span v-if="item.shift != null">
                            ({{ item.shift.name }})
                          </span>
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.status_request`]="{ item }">
                        <div
                          :style="`text-transform:uppercase; font-size:12px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; `"
                        >
                          <span :style="`${statusColor(item.status_request)};`">
                            {{ statusName(item.status_request) }}
                          </span>
                        </div>
                      </template>
                      <template v-slot:[`item.created_by_name`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 11px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.created_by_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }"
                            ><v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="editEmployeeOvertime(item)"
                              small
                              color="primary"
                              class="mr-2"
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="error"
                              @click.stop="deleteItem(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider> </v-divider>
              <v-row no-gutters>
                <v-col cols="12" style="padding: 0 10px">
                  <v-card
                    v-if="
                      detail.approval_matrix !== undefined &&
                      detail.approval_matrix !== null
                    "
                    flat
                    class="mx-auto"
                    style="margin-top: 1px; margin-bottom: 10px"
                  >
                    <v-toolbar-title class="overline">
                      <p
                        style="
                          font-size: 16px;
                          margin: 0;
                          font-weight: bold;
                          color: indigo;
                          margin-bottom: 10px;
                        "
                      >
                        Approval Matrix
                      </p>
                    </v-toolbar-title>
                    <v-data-table
                      v-if="detail.approval_matrix !== null"
                      mobile-breakpoint="0"
                      :headers="approvalHeaders"
                      :items="detail.approval_matrix"
                      class="approvalMatrix elevation-1 mx-auto"
                      :items-per-page="10"
                      :server-items-length="1"
                      hide-default-footer
                    >
                      <template v-slot:[`item.approver_user`]="{ item }">
                        <div
                          style="cursor: pointer; width: 280px; font-size: 12px"
                          @click="approvalRowClick(item)"
                        >
                          <v-chip
                            label
                            small
                            style="
                              margin-right: 5px;
                              padding: 0 3px;
                              cursor: pointer;
                              border: 1px solid rgba(0, 0, 0, 0.5);
                            "
                            v-for="(item, index) in item.approver_user"
                            :key="index"
                          >
                            {{ item.user.name }}
                          </v-chip>
                        </div>
                      </template>
                      <template v-slot:[`item.approved`]="{ item }">
                        <div
                          style="
                            min-width: 90px;
                            max-width: 280px;
                            font-size: 12px;
                          "
                          v-for="(items, index) in item.approver_user"
                          :key="index"
                        >
                          <v-chip
                            v-if="items.status === 1"
                            label
                            small
                            style="
                              margin-right: 5px;
                              padding: 0 3px;
                              cursor: pointer;
                              border: 1px solid rgba(0, 0, 0, 0.5);
                            "
                          >
                            {{ items.user.name }}
                          </v-chip>
                        </div>
                      </template>
                      <template v-slot:[`item.rejected`]="{ item }">
                        <div
                          style="
                            min-width: 90px;
                            max-width: 280px;
                            font-size: 12px;
                          "
                          v-for="(items, index) in item.approver_user"
                          :key="index"
                        >
                          <v-chip
                            v-if="items.status === -1"
                            label
                            small
                            style="
                              margin-right: 5px;
                              padding: 0 3px;
                              cursor: pointer;
                              border: 1px solid rgba(0, 0, 0, 0.5);
                            "
                          >
                            {{ items.user.name }}
                          </v-chip>
                        </div>
                      </template>
                      <template v-slot:[`item.sequence`]="{ item }">
                        <div
                          :style="`width:40px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.sequence }}
                        </div>
                      </template>
                      <template v-slot:[`item.min_approver`]="{ item }">
                        <div
                          :style="`width:90px; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items:center;`"
                        >
                          {{ item.min_approver }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div style="width: 140px; font-size: 12px">
                          {{ item.created_at }}
                        </div>
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          v-for="(items, index) in item.approver_user"
                          :key="index"
                          style="width: 140px; font-size: 12px"
                        >
                          <span v-if="items.status === 1">
                            {{
                              items.updated_at !== null ? items.updated_at : '-'
                            }}
                          </span>
                          <br v-if="index > 0" />
                        </div>
                      </template>
                      <!-- <template v-slot:[`item.document_no`]="{ item }">
                        <div
                          :style="`width:140px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.document_no }}
                        </div>
                      </template>
                      <template v-slot:[`item.table_name`]="{ item }">
                        <div
                          :style="`width:180px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.table_name }}
                        </div>
                      </template> -->
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
              <div
                style="text-align: left; padding-left: 5px"
                v-if="detail.status.id === 1"
              >
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) =>
                        id == appLevel.adminMaster ||
                        id == appLevel.administrator
                    ) != undefined ||
                    detail.created_by == getUserProfile.employee.id
                  "
                  color="grey"
                  tile
                  dark
                  small
                  dense
                  style="font-size: 10px; width: 100px"
                  @click="fetchMatrix"
                  :loading="loading"
                >
                  <span>Fetch Matrix</span>
                </v-btn>
              </div>
            </v-form>
          </v-card>

          <div
            v-if="loading"
            class="d-flex justify-center align-center"
            style="
              position: absolute;
              top: 0;
              z-index: 6;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
            "
          >
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

          <v-dialog v-model="detailItemDialog" persistent max-width="600px">
            <v-card v-if="formItem != null">
              <v-card-title>
                <span class="text-h5">TAMBAH/EDIT DATA</span>
              </v-card-title>
              <v-form
                ref="entryFormItem"
                @submit.prevent="saveEmployeeOvertime"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Karyawan
                        </p>

                        <v-autocomplete
                          v-model="formItem.employee"
                          :items="dropdown.employee"
                          :search-input.sync="employeeSearch"
                          :rules="rules.employeeRules"
                          item-text="name"
                          item-value="id"
                          return-object
                          outlined
                          dense
                          style="margin: 0; height: 45px"
                          clearable
                          :disabled="detail.company == null"
                        >
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-avatar>
                                <img :src="`${data.item.photo}`" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ data.item.company_name }}-{{
                                    data.item.nik
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Tanggal lembur
                        </p>
                        <v-text-field
                          v-model="detail.date"
                          dense
                          outlined
                          type="date"
                          name="date"
                          step="1"
                          readonly
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Pilih Shift
                        </p>

                        <v-autocomplete
                          v-model="formItem.shift"
                          :items="dropdown.shift"
                          :rules="rules.shiftRules"
                          @change="shiftWatcher"
                          item-text="name"
                          item-value="id"
                          return-object
                          outlined
                          dense
                          style="margin: 0; height: 45px"
                          clearable
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Waktu mulai
                        </p>
                        <v-text-field
                          v-model="formItem.start_time"
                          :rules="rules.startRules"
                          @change="startWatcher"
                          clearable
                          dense
                          outlined
                          type="datetime-local"
                          name="datetime-local"
                          step="1"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Waktu berakhir
                        </p>
                        <v-text-field
                          v-model="formItem.end_time"
                          :rules="rules.endRules"
                          @change="endWatcher"
                          clearable
                          dense
                          outlined
                          type="datetime-local"
                          name="datetime-local"
                          step="1"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Total waktu
                        </p>
                        <v-text-field
                          v-model="formItem.total_hour"
                          disabled
                          dense
                          outlined
                          type="number"
                          min="0"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="
                          padding: 0 0 0 10px;
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          margin-top: 20px;
                        "
                      >
                        <v-col
                          cols="6"
                          style="
                            padding: 0;
                            justify-content: flex-start;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <p
                            style="
                              margin: 0 0 0 5px;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Dipotong istirahat ?
                          </p>
                          <v-checkbox
                            v-model="formItem.is_break"
                            :false-value="false"
                            :true-value="true"
                            style="margin: 0 0 0 10px; height: 25px; padding: 0"
                          ></v-checkbox>
                        </v-col>
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Alasan
                        </p>
                        <v-textarea
                          v-model="formItem.reason"
                          dense
                          auto-grow
                          outlined
                          counter="255"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeEmployeeOvertimeDialog"
                  >
                    Tutup
                  </v-btn>
                  <v-btn
                    v-if="permission.create_perm == 1"
                    color="blue darken-1"
                    text
                    type="submit"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </div>
    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      loading: false,
      detail: null,
      form: null,
      formItem: null,
      duplicateItem: [],
      isEdit: false,
      detailItemDialog: false,
      wWidth: window.innerWidth,
      isAllowApprove: false,
      footer: {
        itemsPerPage: 10,
        options: {},
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        sortBy: 'id',
        sortType: 'ASC',
        department_id: null
      },
      detailHeaders: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status_request',
          sortable: false
        },
        {
          text: 'No. Pengajuan',
          value: 'request_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Karyawan',
          value: 'employee.name',
          sortable: false
        },
        {
          text: 'NIP',
          value: 'employee.nik',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company.name',
          sortable: false
        },
        {
          text: 'Shift',
          value: 'shift',
          sortable: false
        },
        {
          text: 'Waktu mulai',
          value: 'request_start_time',
          sortable: false
        },
        {
          text: 'Waktu berakhir',
          value: 'request_end_time',
          sortable: false
        },
        {
          text: 'Total waktu lembur',
          value: 'total_hour',
          sortable: false
        },
        {
          text: 'Pembulatan',
          value: 'total_hour_rounding',
          sortable: false
        },
        {
          text: 'Istirahat ?',
          value: 'is_break',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'created_at',
          align: 'left',
          sortable: false
        },
        {
          text: 'Dibuat Oleh',
          value: 'created_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Diupdate pada',
          value: 'updated_at',
          align: 'left',
          sortable: false
        }
      ],
      dropdown: {
        employee: [],
        department: [],
        shift: []
      },
      searchName: '',
      employeeSearch: null,
      employeeSelect: null,
      rules: {
        employeeRules: [],
        startRules: [],
        endRules: [],
        dateRules: [],
        shiftRules: []
      },
      approveDialog: false,
      rejectDialog: false,
      cancelDialog: false,
      approvalHeaders: [
        { text: 'No', value: 'sequence', align: 'left' },
        // { text: 'Doc. No', value: 'document_no', align: 'left', width: '10%' },
        // { text: 'Model', value: 'table_name', align: 'center', width: '10%' },
        { text: 'User', value: 'approver_user', align: 'center' },
        {
          text: 'Min Approve',
          value: 'min_approver',
          align: 'center'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.employeeSelect && this.querySelections(val)
        } else {
          this.employeeSelect = null
          this.dropdown.employee = []
        }
      } else {
        this.employeeSelect = null
        this.dropdown.employee = []
      }
    },
    'formItem.employee'() {
      if (this.formItem == null || this.formItem.employee == null) {
        this.employeeSearch = null
        this.employeeSelect = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations([]),
    approvalRowClick(pItem) {
      console.log(pItem)
    },
    async dropdownShift() {
      const url = `${this.hrsApi}master/universal/shift/dropdown?filter=[{"company_id":${this.detail.company.id}}]`
      // if (this.det.company != null) {
      //   url = url + `?filter=[{"company_id":${this.form.company.id}}]`
      // }
      await axios
        .get(url)
        .then((res) => {
          console.log('shift>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.shift = res.data.data)
          }
          return (this.dropdown.shift = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.shift = [])
        })
    },
    shiftWatcher(val) {
      if (this.formItem != null && this.formItem.shift != null) {
        var start = ''
        var end = this.detail.date + 'T' + val.end_time
        if (val.start_time >= '20:00:00' && val.start_time <= '23:59:59') {
          var newDate = moment(this.detail.date)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
          start = newDate + 'T' + val.start_time
        } else {
          start = this.detail.date + 'T' + val.start_time
        }

        this.formItem.start_time = start
        this.formItem.end_time = end

        if (
          this.formItem.start_time !== null &&
          this.formItem.end_time !== null
        ) {
          this.getDifferencies()
        }
      } else {
        this.formItem.start_time = null
        this.formItem.end_time = null
      }
    },
    startWatcher(val) {
      if (this.formItem !== null) {
        if (
          this.formItem.start_time !== null &&
          this.formItem.end_time !== null
        ) {
          this.getDifferencies()
        }
      }
    },
    endWatcher(val) {
      if (this.formItem !== null) {
        if (
          this.formItem.start_time !== null &&
          this.formItem.end_time !== null
        ) {
          this.getDifferencies()
        }
      }
    },
    closeDocument() {
      if (this.detail !== null) {
        this.$refs.entryForm.reset()
      }
      this.$router.push('/hr/overtime')
    },
    authorityCheck(item) {
      console.log('isAllowApprove', this.isAllowApprove)
      if (item.approval_matrix !== null) {
        const matrixId = item.approval_matrix[0].document_id
        const url = `${this.oAuthApi}approval_matrix/document/allow_user?document_id=${matrixId}&application_id=21`
        console.log('check authority', url)
        axios
          .get(url)
          .then((res) => {
            if (res.data.status_code !== '-99') {
              if (res.data.is_allow_approve !== 0) {
                this.isAllowApprove = true
              } else {
                this.isAllowApprove = false
              }
            } else {
              this.isAllowApprove = false
            }
          })
          .catch((err) => {
            console.log(err)
            // this.showMsgDialog(
            //   'error',
            //   err
            //     ? 'Cek Otoritas error, Silahkan info ke Admin'
            //     : 'Cek Otoritas error, Silahkan info ke Admin',
            //   false
            // )
          })
      }
    },
    openCancelDialog() {
      setTimeout(() => {
        this.cancelDialog = true
      }, 300)
    },
    closeCancelDialog() {
      setTimeout(() => {
        this.cancelDialog = false
      }, 300)
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      this.isEdit = false

      this.getDetail()
    },
    async querySelections(v) {
      this.loading = true

      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.detail.company_id != null) {
        url = url + `&company_id=${this.detail.company.id}`
      }
      await axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.loading = false
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    async dropdownDepartment(v) {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.detail.company.id}}]`
        )
        .then((res) => {
          console.log('dropdownDepartment>>>>', res)
          let arrRes = []
          if (res.data.status_code === '00') {
            arrRes = res.data.data
          }
          this.dropdown.department = arrRes
          return null
        })
        .catch((err) => {
          this.dropdown.department = []
          return console.log(err)
        })
    },
    async updatePage(p) {
      this.footer.page = p
      this.footer.offset = p - 1
    },
    async updateItemPerPage(p) {
      this.footer.limit = p
      this.footer.offset = 0
    },
    async getDetail() {
      this.loading = true
      const url = `${this.hrsApi}employee/overtime_document/detail/${this.$route.params.id}`
      await axios
        .get(url)
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code == '00') {
            this.detail = res.data.data
            if (res.data.data.status.id == 1) {
              this.authorityCheck(res.data.data)
            }
            this.dropdownDepartment()
            this.dropdownShift()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.loading = false
          return null
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    editHeader() {
      this.form = {
        company: this.detail.company,
        department_id:
          this.detail.department != null ? this.detail.department.id : null,
        date: this.detail.date,
        description: this.detail.description
      }
      setTimeout(() => {
        this.isEdit = true
      }, 200)
    },
    cancelHeader() {
      this.form = null
      this.getDetail()
      setTimeout(() => {
        this.isEdit = false
      }, 100)
    },

    submitHeader() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: 'update',
        name: this.detail.name,
        company_id: this.detail.company_id,
        company_name: this.detail.company_name
      }
      if (form.act === 'update') {
        Object.assign(form, {
          id: this.detail.id
        })
      }
      this.saveHeader(form)
    },

    async saveHeader(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_additional/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.cancelHeader()
          return null
        })
        .catch((err) => {
          this.cancelHeader()
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    async addEmployeeOvertime(val) {
      this.formItem = {
        act: 'add_from_document',
        employee: null,
        company: this.detail.company,
        department: this.detail.department,
        start_time: null,
        end_time: null,
        date: this.detail.date,
        reason: '',
        total_hour: 0,
        total_hour_rounding: 0,
        multiplier: 0,
        is_break: true
      }
      // if (this.permission.update_perm == 1) {
      setTimeout(() => {
        this.detailItemDialog = true
      }, 300)
      // }
    },
    async editEmployeeOvertime(pItem) {
      console.log(this.detail, pItem)
      this.formItem = {
        act: 'update',
        employee: this.detail.employee,
        company: this.detail.company,
        department: this.detail.department,
        start_time: this.detail.request_start_time,
        end_time: this.detail.request_end_time,
        date: this.detail.date,
        reason: this.detail.reason,
        total_hour: this.detail.total_hour,
        total_hour_rounding: this.detail.total_hour_rounding,
        multiplier: this.detail.multiplier,
        is_break: this.detail.is_break
      }
      // if (this.permission.update_perm == 1) {
      setTimeout(() => {
        this.detailItemDialog = true
      }, 100)
      // }
    },
    async openDetail(pItem) {
      setTimeout(() => {
        this.$router.push(`/hr/overtime/detail/${pItem.id}`)
      }, 100)
    },
    closeEmployeeOvertimeDialog() {
      this.detailItemDialog = false
      // this.$refs.entryFormItem.reset()
      setTimeout(() => {
        this.formItem = null
      }, 300)
    },
    saveEmployeeOvertime() {
      if (this.permission.create_perm == 1) {
        // this.rules.companyRules = [v => !!v || 'Company is required']
        this.rules.employeeRules = [(v) => !!v || 'Employee is required']
        this.rules.startRules = [(v) => !!v || 'Start is required']
        this.rules.endRules = [(v) => !!v || 'End is required']
        if (this.formItem.shift != null) {
          this.rules.shiftRules = [(v) => !!v || 'Shift is required']
          this.rules.dateRules = [(v) => !!v || 'Date is required']
        }
        const self = this
        setTimeout(function () {
          if (self.$refs.entryFormItem.validate()) {
            // self.createNewForm()
            const form = {
              act: self.formItem.act,
              employee_id: Number(self.formItem.employee.id),
              company_id: Number(self.formItem.company.id),
              company_name: self.formItem.company.name,

              request_start_time: self.formItem.start_time,
              request_end_time: self.formItem.end_time,
              total_hour: self.formItem.total_hour,
              total_hour_rounding: self.formItem.total_hour_rounding,
              multiplier: self.formItem.multiplier,
              reason: self.formItem.reason,
              is_break: self.formItem.is_break,
              shift_id:
                self.formItem.shift != null ? self.formItem.shift.id : null
            }
            if (self.formItem.act == 'add_from_document') {
              Object.assign(form, {
                document_id: self.detail.id
              })
            }
            if (self.formItem.act == 'update') {
              Object.assign(form, { id: self.formItem.id })
            }

            console.log('Formulir>>>>', form)
            self.submitEmployeeOvertime(form)
          }
        })
      }
    },

    async submitEmployeeOvertime(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_request/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeEmployeeOvertimeDialog()
            this.getDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.cancelHeader()
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async deleteItem(pItem) {
      // this.loading = true
      // this.showMsgDialog(
      //   'question',
      //   'Are you sure, This data will be deleted permanently',
      //   true
      // ).then(async (res) => {
      //   if (res.isConfirmed) {
      //     await axios
      //       .delete(
      //         `${this.hrsApi}employee/payroll_additional/delete_detail/${pItem.id}`
      //       )
      //       .then(async (res) => {
      //         if (res.data.status_code !== '-99') {
      //           this.showMsgDialog('success', res.data.status_msg, false)
      //           this.getDetail()
      //         } else {
      //           this.showMsgDialog('warning', res.data.status_msg, false)
      //         }
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //         this.showMsgDialog(
      //           'error',
      //           err
      //             ? 'Something went wrong, Please contact an admin!'
      //             : 'Something went wrong, Please contact an admin!',
      //           false
      //         )
      //       })
      //   }
      //   this.loading = false
      // })
    },
    close() {
      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/hr/overtime') {
          this.$router.push(this.prevRoute.path)
          return ''
        }
      }
      this.$router.push('/hr/overtime')
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MEI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'color: red;'
        case -1:
          return 'color: red;'
        case 0:
          return 'color: black;'
        case 1:
          return 'color: rgb(138, 90, 0);'
        case 2:
          return 'color: green;'
        // case 3:
        //   return 'color: #1976d2; background-color: #4e4e4e1f;'
        // case 4:
        //   return 'color: green; background-color: #4e4e4e1f;'
        // case 5:
        //   return 'color: red; background-color: #4e4e4e1f;'
        // case 6:
        //   return 'color: red; background-color: #4e4e4e1f;'
        default:
          return 'black'
      }
    },

    async submitDocument() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/submit`, {
          // act: 'submit',
          id: this.$route.params.id
        })
        .then(async (res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)

            if (this.detail.approval_matrix !== null) {
              this.fetchMatrix()
              console.log('fetch done')
            } else {
              this.getDetail()
            }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async confirmOvertime() {
      const document_id =
        this.detail.approval_matrix != null
          ? this.detail.approval_matrix[0].document_id
          : this.$route.params.id
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/approve`, {
          document_id: document_id,
          note: this.detail.confirmed_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.loadLeaveApprovalNeeds()
            this.getDetail()
            this.approveDialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async rejectOvertime() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/reject`, {
          id: this.$route.params.id,
          note: this.detail.confirmed_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            // this.loadLeaveApprovalNeeds()
            this.getDetail()
            this.rejectDialog = false
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async hrCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/cancel`, {
          id: this.$route.params.id,
          note: this.detail.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
            this.closeCancelDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async selfCancel() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/cancel`, {
          id: this.$route.params.id,
          note: this.detail.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
            this.closeCancelDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async setToDraft() {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/set_to_draft`, {
          id: this.$route.params.id
          // note: this.form.cancel_note
        })
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDetail()
            // this.fetchMatrix()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },

    fetchMatrixV1() {
      const form = {
        act: 'fetch_matrix',
        document_id: this.detail.id,
        document_no: this.detail.document_no,
        application_id: 21,
        table_name: 'tr_employeerequestovertimedocuments',
        company_id: this.detail.company.id,
        department_id: this.detail.department.id
      }
      this.loading = true
      axios
        .post(`${this.oAuthApi}approval_matrix/document/save`, form)
        .then((res) => {
          console.log('fetch>>', res)
          this.getDetail()
          this.loading = false
        })
        .catch((err) => {
          console.log('fetch error>>', err)
          this.loading = false
        })
    },

    async fetchMatrix() {
      this.loading = true
      const form = {
        id: this.$route.params.id
      }
      await axios
        .post(`${this.hrsApi}employee/overtime_document/fetch_matrix`, form)
        .then(async (res) => {
          if (res.data.status_code == '00') {
            this.getDetail()
          }
          this.loading = false
          this.showMsgDialog(
            res.data.status_code == '00' ? 'success' : 'warning',
            res.data.status_msg,
            false
          )
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    statusName(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        default:
          break
      }
    },

    getDifferencies() {
      const timeStart = moment(this.formItem.start_time)
      const timeEnd = moment(this.formItem.end_time)

      var diff_s = timeEnd.diff(timeStart)
      var x = moment.duration(diff_s)
      var result = x / 60 / 60 / 1000

      this.formItem.total_hour = result.toFixed(2)

      // const rounding = Math.round(result * 100) / 100
      this.formItem.total_hour_rounding = Math.round(result)
    }
  }
}
</script>
<style lang="scss" scoped>
.actionButton {
  position: relative;
  display: flex;
  // width: 400px;
}
</style>
